import React from "react";

export default function PhotoContainer({ photo, idx, photos }) {
  return (
    <div style={{ minWidth: "100vw", height: "100vh" }}>
      <img
        style={{
          height: idx === 13 ? "102%" : "100%",
          minWidth: "100%",
          maxWidth: "100%",
        }}
        src={`/smiles/${photos[idx]}.png`}
      />
    </div>
  );
}
