import "./App.css";
import PhotoContainer from "./modules/PhotoContainer";
import Landing from "./modules/Landing";
import { useEffect, useState } from "react";

function App() {
  const [photoIdx, setIdx] = useState(-1);
  const [isMobile, setIsMobile] = useState(false);

  let imgArr = [
    "green-smiles",
    "typography",
    "sheep",
    "old-photo",
    "blue-n-orange",
    "evans-smile",
    "smiles-in-grass",
    "vintage",
    "red-blush",
    "kid",
    "house-smile",
    "dot2dot",
    "dad",
    "monkey",
    "sweetie",
    "pink-smiles",
    "maddie",
  ];

  const handleKeyPress = (e) => {
    const key = e.keyCode;

    if (key === 32) {
      if (photoIdx === imgArr.length - 1) {
        setIdx(0);
      } else {
        setIdx((prevState) => prevState + 1);
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  return (
    <div
      className="App"
      style={{
        maxHeight: "100vh",
        overflow: "hidden",
        maxWidth: "100vw",
      }}
    >
      <div
        style={{
          transition: "0.6s linear",
          width: window.innerWidth * imgArr.length + 1,
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
        tabIndex="0"
        onKeyDown={(e) => handleKeyPress(e)}
      >
        {photoIdx === -1 ? (
          <Landing isMobile={isMobile} />
        ) : (
          <PhotoContainer photos={imgArr} idx={photoIdx} />
        )}
      </div>
    </div>
  );
}

export default App;
