import React, { useState } from "react";

export default function Landing({ isMobile }) {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div
      style={{
        minWidth: "100vw",
        height: "100vh",
      }}
    >
      {isClicked ? (
        <div
          style={{
            height: "26vh",
            width: "17vw",
            position: "relative",
            marginLeft: "10vw",
            marginTop: "14vh",
          }}
        >
          <img
            src="/smiles/text-logo.png"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ) : null}
      <>
        {isClicked ? (
          <div
            onClick={() => setIsClicked(true)}
            className="floating-text"
            style={{
              position: "absolute",
              bottom: "32vh",
              left: "50vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              cursor: isClicked ? "auto" : "pointer",
            }}
          >
            <p style={{ fontSize: 20, fontFamily: "Helvetica Neue" }}>
              Press the space bar to smile
            </p>
          </div>
        ) : (
          <div
            onClick={() => setIsClicked(true)}
            className="floating-div"
            style={{
              position: "absolute",
              bottom: "32vh",
              left: "50vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              cursor: isClicked ? "auto" : "pointer",
            }}
          >
            <img
              src="/smiles/dad.png"
              style={{ width: "20vw", height: "10vw" }}
            />
            <p>{isMobile ? "Switch to desktop to get smiling" : "click me"}</p>
          </div>
        )}
      </>
    </div>
  );
}
